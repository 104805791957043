<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>企业管理</a-breadcrumb-item>
            <a-breadcrumb-item>Labs</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
            <div class="buttons">
            </div>
            <div class="search">
                <a-form layout='inline'>
                <a-form-item>
                <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                    <a-radio-button value="day">
                    日
                    </a-radio-button>
                    <a-radio-button value="week">
                    周
                    </a-radio-button>
                    <a-radio-button value="month">
                    月
                    </a-radio-button>
                    <a-radio-button value="year">
                    年
                    </a-radio-button>
                </a-radio-group>
                </a-form-item>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>时间筛选</span>
                    </template>
                    <a-range-picker
                        :ranges="rangesData"
                        :value='rangesValue'
                        :disabled-date="disabledDate" :allowClear="false"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                        @change="(date, dateString)=>onChange(date, dateString,1)" />
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-select style="width:160px" v-model='paramsData.wid' @search="handleSearch" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>股票代码</span>
                    </template>
                    <a-input v-model.trim="paramsData.stock_name" allowClear placeholder="股票代码" style="width: 180px"/>
                </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-button @click="toScreen" type="primary">筛选</a-button>
                </a-form-item>
                </a-form>
            </div>
            </div>
            <div class="dashboard bk-group">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <statisticsCount :count='counts' :col="6"/>
            <a-row class="mb16" :gutter="16">

                <a-col :lg="24" :xl="24">
                    <LRanking title="月涨幅" :rank_data="weekLong" :loading="loadingC"/>
                </a-col>

                <!-- <a-col :lg="24" :xl="24">
                    <statisticsPayment :loading='loadingP' title="均线占比"  :leads_data ='longChart' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                </a-col> -->

                <!-- <a-col :lg="18" :xl="18">
                    <statisticsPayment :loading='loadingP' title="流入占比"  :leads_data ='predictChart' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                </a-col> -->

                <!-- <a-col :lg="6" :xl="6">
                    <a-card size="small">
                        <template>
                            <a-timeline>
                                <a-timeline-item :color="msg.color" v-for="(msg,index) in predictMsg" :key="index">
                                    {{msg.stock_date}} {{msg.tm}} {{msg.msg}}
                                </a-timeline-item>
                            </a-timeline>
                        </template>
                    </a-card>
                </a-col> -->

                <a-col :lg="24" :xl="24">
                    <a-card size="small" title="BK">
                    <vue-element-loading :active="loadingS" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' :columns="col_stock_columns" :dataSource="bkTable" :scroll="{ x: RelativeWidth, y:700}">
                            <template slot="short" slot-scope="text, record , index">
                                <div @click="bkShort(record[text])" :style="border(record[text].color)">
                                    <div>{{record[text].name}}</div>
                                    <div style="color:green"> {{ (formatNumber(record[text].hands)) }}</div>
                                </div>
                            </template>
                        </a-table>
                    </a-card> 
                </a-col>

                <a-col :lg="24" :xl="24">
                    <a-card size="small" title="SHORT">
                    <vue-element-loading :active="loadingS" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' :columns="col_stock_columns" :dataSource="col_stock_rows" :scroll="{ x: RelativeWidth, y:700}">
                            <template slot="short" slot-scope="text, record , index">
                                <div @click="stockShort(record[text])" :style="border(record[text].color)">
                                    <div>{{record[text].name}}</div>
                                    <div style="color:green"> {{ (formatNumber(record[text].hands)) }}</div>
                                </div>
                            </template>
                        </a-table>
                    </a-card> 
                </a-col>

                <a-col :lg="24" :xl="24">
                    <a-card size="small">
                        <div class="table">
                            <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                                :columns="columns" :dataSource="liuruList">
                                <template slot="index" slot-scope="text, record , index">
                                    <span>{{(index+1)}}</span>
                                </template>

                                <template slot="price" slot-scope="text, record , index">
                                    <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
                                </template>
                            </a-table>
                        </div>
                    </a-card>
                </a-col>

                <!-- <a-col :lg="24" :xl="12" v-for="(d, index) of lxsz">
                    <LRanking :title="index" :rank_data="d" :loading="loadingC" :start_date='index' :end_date='index' />
                </a-col> -->

                <!-- <a-col :lg="24" :xl="24">
                    <LRanking title="累计涨幅" :rank_data="stillLong" :loading="loadingC"/>
                </a-col> -->

                <!-- <a-col :lg="24" :xl="24">
                    <heatmap key="1" :loading="loadingC" :item="stockHeatmap"/>
                </a-col> -->

                <!-- <a-col :lg="24" :xl="24">
                    <a-col :lg="24" :xl="12">
                        <LRanking title="持续累计涨幅" :rank_data="stockLong" :loading="loadingC"/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="今日累计涨幅" :rank_data="stockLongList" :loading="loadingC"/>
                    </a-col>
                </a-col> -->

                <!-- <a-col :lg="24" :xl="12">
                    <LRanking title="停止累计涨幅" :rank_data="stockStopLong" :loading="loadingC"/>
                </a-col>

                <a-col :lg="24" :xl="12">
                    <LRanking title="停止今日涨幅" :rank_data="stockStopLongNow" :loading="loadingC"/>
                </a-col> -->
            </a-row>
            </div>
        </div>
        <a-modal v-model="msgShow" width="550px" :title="shortTitle" :footer="null">
            <template>
                <a-timeline>
                    <a-timeline-item :color="msg.color" v-for="(msg,index) in currMsg" :key="index">
                        <!-- {{msg.stock_date}} {{msg.tm}} {{msg.msg}} -->
                        {{msg.msg}}
                    </a-timeline-item>
                </a-timeline>
            </template>
        </a-modal>
    </div>
</template>

<script>
const columns = [
  // { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' },
  { title: '日期', align:'center', dataIndex: 'stock_date', key: 'stock_date'},
//   { title: '开盘', align:'center', dataIndex: 'stock_jinkai', key: 'stock_jinkai'},
//   { title: '价格', align:'center', dataIndex: 'stock_now', key: 'stock_now'},
  { title: '换手', align:'center', dataIndex: 'stock_huanshou', key: 'stock_huanshou'},
//   { title: '振幅', align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu'},
//   { title: '涨幅', align:'left', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu', scopedSlots: { customRender: 'price' }},
  { title: '成交额', align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe', scopedSlots: { customRender: 'price' }},
  { title: '净流入',  align:'center', dataIndex: 'stock_liuru', key: 'stock_liuru', scopedSlots: { customRender: 'price' }},
//   { title: '流入占比', align:'center', dataIndex: 'stock_liuru_zhanbi', key: 'stock_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '超大流入', align:'center', dataIndex: 'stock_chaoda_liuru', key: 'stock_chaoda_liuru', scopedSlots: { customRender: 'price' }},
//   { title: '超大占比', align:'center', dataIndex: 'stock_chaoda_liuru_zhanbi', key: 'stock_chaoda_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '大流入', align:'center', dataIndex: 'stock_da_liuru', key: 'stock_da_liuru', scopedSlots: { customRender: 'price' }},
//   { title: '大占比',  align:'center', dataIndex: 'stock_da_liuru_zhanbi', key: 'stock_da_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '中流入', align:'center', dataIndex: 'stock_zhong_liuru', key: 'stock_zhong_liuru', scopedSlots: { customRender: 'price' }},
//   { title: '中占比', align:'center', dataIndex: 'stock_zhong_liuru_zhanbi', key: 'stock_zhong_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '小流入', align:'center', dataIndex: 'stock_xiao_liuru', key: 'stock_xiao_liuru', scopedSlots: { customRender: 'price' }},
//   { title: '小占比', align:'center', dataIndex: 'stock_xiao_liuru_zhanbi', key: 'stock_xiao_liuru_zhanbi', scopedSlots: { customRender: 'price' }}
] 
import userHeatmap from './userHeatmap'
import tableMixins from '@/common/mixins/table'
import employeeHeatmap from './employeeHeatmap'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import heatmap from './heatmap'
import statisticsLine from './statisticsLine'
import dailyActive from './dailyActive'
import statisticsPayment from './leads_follow_count'
import statisticsCount from '@/views/bi/ranking/money/statistics'
    export default {
        name:'statistics',
        mixins: [ tableMixins , ranges],
        computed: {
            RelativeWidth() {
                let width = 850
                if(this.filedLength >10){
                width = 900 + ( this.filedLength - 10 ) * 130
                }
                return width
            }
        },
        data() {
            return {
                columns,
                msgShow:false,
                loading:false,
                loadingC:false,
                loadingP:false,
                loadingS:false,
                loadingF:false,
                loadingDA:false,
                loadingBT:false,
                loadingBD:false,
                loadingUH:false,
                loadingEH:false,
                start_date:'',
                end_date:'',
                predictMsg:[],
                currMsg:[],
                liuruList:[],
                paramsData:{
                    filter_type:'day',
                    start_date:'',
                    end_date:'',
                },
                col_stock_rows:[],
                col_stock_columns:[],
                predictChart:[],
                longChart:[],
                bkTable:{},
                shortMsgList:[],
                modalData:{},
                paymentDate:{},
                incomeDate:{},
                conceptList:[],
                financeCountDate:{},
                financeSummateDate:{},
                bankTypeCount:{},
                lxsz:{},
                stockHeatmap:{},
                currStock:{},
                stockLong:{},
                stillLong:{},
                weekLong:{},
                stockLongList:{},
                stockStopLong:{},
                stockStopLongNow:{},
                bankTypeSummate:{},
                heatmapUhData:{},
                heatmapEhData:{},
                bookData:{},
                counts:[],
                appList:[]
            }
        },
        mixins: [ranges],
        components:{statisticsLine,statisticsCount,dailyActive,statisticsPayment,userHeatmap,employeeHeatmap,heatmap},
        created () {
            this.paramsData.start_date = moment().subtract('days',15).format('YYYY-MM-DD')
            this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getCount()
            this.getPredictChart()
            this.getApp()
            this.getList()
            this.getPayment()
            this.getBook()
            this.getFinance()
            this.getStockChart()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            shortTitle(){
                console.log(this.conceptList)
                return this.currStock.name + ' - '+ this.conceptList[this.currStock.code];
            },
            bkShort(record){
                console.log(record);
            },
            stockShort(record){
                console.log(record);
                this.currMsg = this.shortMsgList[record.code];
                this.currStock = record;
                this.msgShow = true;
            },
            border(value){
                return {"border": "3px solid " + value};
            },
            
            color(value){
                if(value < 0){
                    return {color:'green'};
                }
                if(value==0){
                    return '';
                }
                if(value > 0){
                    return {color:'red'};
                }
            },
            formatNumber(value) {
                let flag = '';
                if(value<0){
                    value=Math.abs(value)
                    flag = '-';
                }
                if (value >= 100000000) {
                    return flag+(value / 100000000).toFixed(2) + ' 亿';
                } else if (value >= 10000000) {
                    return flag+(value / 10000000).toFixed(2) + ' 千万';
                }else if (value >= 1000) {
                    return flag+(value / 10000).toFixed(2) + ' 万';
                } else {
                    return flag+value;
                }
            },
            async getStockChart() {
                this.loadingP = true;
                this.loadingS = true;
                let res = await this.$store.dispatch('enterpriseLabStockAction', {data:{search:this.paramsData}})

                let stock_col_date = [
                    // { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index'},
                    { title: '时间',width:'75px', dataIndex: 'time', key: 'time'}
                ];

                if(res.data.short_table){
                    res.data.short_table.col_date.map(item=>{
                        let obj = { title: item, dataIndex: item, key: item,align:'right',width:'100px', scopedSlots: { customRender: 'short' }}
                        stock_col_date.push(obj)
                    })
                }

                this.col_stock_columns = stock_col_date;
                this.col_stock_rows = res.data.short_table.rows;

                this.bkTable = res.data.bk_table.rows

                this.conceptList = res.data.concept_list;
                this.shortMsgList = res.data.short_msg_list;

                this.lxsz = res.data.lxsz;
                this.longChart = res.data.avg_chart;
                this.stockLong = res.data.stock_long;
                this.weekLong = res.data.week_stock_long;
                this.stockStopLong = res.data.stop_long;
                this.stockLongList = res.data.stock_long_now
                this.stockStopLongNow = res.data.stop_long_now
                this.stockHeatmap = res.data.long_heatmap
                this.stillLong = res.data.still_stock_long


                this.loadingS = false;
                this.loadingP = false
            },
            async getPredictChart() {
                // this.loading = true
                let res = await this.$store.dispatch('enterpriseLabIndexAction', {data:{search:this.paramsData}})
                this.predictChart = res.data.predict_chart
                this.liuruList = res.data.liuru_list
                this.predictMsg = res.data.short_msg
                // this.modalData= res.data
                // this.loadingP = false
            },
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('enterpriseStatisticsAction', {data:{search:this.paramsData}})
                this.modalData= res.data
                this.loading = false
            },
            async getCount() {
                let res = await this.$store.dispatch('enterpriseStatisticsCountAction')
                this.counts= res.data
            },
            async getPayment(){
                this.loadingP = true
                let res = await this.$store.dispatch('enterpriseStatisticsPaymentAction',{data:{search:this.paramsData}})
                this.paymentDate= res.data
                this.loadingP = false
            },
            async getDailyActive(){
                this.loadingDA = true
                let res = await this.$store.dispatch('enterpriseStatisticsDailyActiveAction',{data:{search:this.paramsData}})
                this.incomeDate= res.data
                this.loadingDA = false
            },
            async getBankType() {
                this.loadingBT = true
                let res = await this.$store.dispatch('enterpriseStatisticsBankTypeAction', {data:{search:this.paramsData}})
                this.bankTypeCount= res.data.count
                this.bankTypeSummate= res.data.summate
                this.loadingBT = false
            },
            async getFinance(){
                this.loadingF = true
                let res = await this.$store.dispatch('enterpriseStatisticsFinanceAction',{data:{search:this.paramsData}})
                this.financeCountDate= res.data.count
                this.financeSummateDate= res.data.summate
                this.loadingF = false
            },
            async getBook(){
                this.loadingBD = true
                let res = await this.$store.dispatch('enterpriseStatisticsBookAction',{data:{search:this.paramsData}})
                this.bookData= res.data
                this.loadingBD = false
            },
            async getApp(val){
                await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
                .then((res)=>{
                    this.appList = res.data
                })
            },
            async getUserHeatmap(){
                this.loadingUH = true
                let res = await this.$store.dispatch('enterpriseStatisticsDailyUserHeatmapAction',{data:{search:this.paramsData}})
                this.heatmapUhData= res.data
                this.loadingUH = false
            },
            async getEmployeeHeatmap(){
                this.loadingEH = true
                let res = await this.$store.dispatch('enterpriseStatisticsDailyEmployeeHeatmapAction',{data:{search:this.paramsData}})
                this.heatmapEhData= res.data
                this.loadingEH = false
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            toScreen(){
                this.getPredictChart()
                this.getList()
                this.getPayment()
                this.getBook()
                this.getFinance()
                this.getBankType()
                this.getDailyActive()
                this.getUserHeatmap()
                this.getEmployeeHeatmap()
                this.getStockChart()
            },
            handleSearch(val){
                this.getApp(val)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>