<template>
  <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-heatmap
              :data="item" 
              judge-width
              :height="wHeight"
              :title="{
                  textStyle: {
                      fontSize: 15,
                  },
                  subtextStyle: {
                      fontSize: 12.5
                  }
              }"
              :extend="{
                series:{
                  itemStyle:{
                    borderColor:'#fff',
                    borderWidth:1
                  },
                  label:{
                    show:true
                  }
                }
              }"
              :settings="chartSettings" 
              :visual-map="chartVisualMap" 
              :grid="chartGrid"
              >
            </ve-heatmap>
            <LEmpty v-if=" item.rows && item.rows.length == 0"/>
        </div>
    </a-card>
</template>

<script>
  import moment from 'moment'
  export default {
    name:'heatmap',
    inject: ['parent'],
    data () {
      this.chartSettings = {
        yAxisList: this.item.yAxisList
      }
      this.chartGrid = {
        right: 10,
        bottom: '10%',
      }
      this.chartVisualMap = {
        show: true,
        min: 0,
        max: 100,
        type: 'piecewise',
        orient:'horizontal',
        left:"center",
        pieces: [
                {
                    lte:  0,
                    color:'#AAAAAA',
                    symbol:'circle'
                }, 
                {
                    gt: 0,   //大于3 小于等于6
                    lte:  5,
                    color:'#e6f7ff',
                    symbol:'circle'
                }, 
                {
                    gt: 5,   //大于3 小于等于6
                    lte: 10 ,
                    color:'#bae7ff',
                    symbol:'circle'
                }, 
                {
                    gt: 10,   //大于3 小于等于6
                    lte: 15 ,
                    color:'#91d5ff',
                    symbol:'circle'
                }, 
                {
                    gt: 15,   //大于3 小于等于6
                    lte: 20 ,
                    color:'#91d5ff',
                    symbol:'circle'
                }, 
                {
                    gt: 20,   //大于3 小于等于6
                    lte: 25 ,
                    color:'#1890ff',
                    symbol:'circle'
                }, 
                {
                    gt: 25,   //大于3 小于等于6
                    lte: 30 ,
                    color:'#0f77d4',
                    symbol:'circle'
                },               
                {
                    gt: 30,   //大于3 小于等于6
                    lte: 35 ,
                    color:'#0745a8',
                    symbol:'circle'
                },     
                {
                    gt: 35,   //大于3 小于等于6
                    lte: 40,
                    color:'#043080',
                    symbol:'circle'
                },         
                {
                    gt: 40,   //大于3 小于等于6
                    color:'#022768',
                    symbol:'circle'
                },               
              ],
      }
      return {
        type:0,
        setHeight:400
      }
    },
    props:['item','loading','title','start_date','end_date','filter_type'],
    computed: {
      wHeight() {
          let height = this.setHeight
          if( this.item.yAxisList && this.item.yAxisList.length > 10){
              height = this.setHeight + ( this.item.yAxisList.length - 10 ) * 20
          }
          return `${height}px`
      }
    },
  }
</script>
